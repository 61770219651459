
import React, { Component } from 'react';


import './style.scss';


class ButtonOrangeCircle extends Component {

    constructor(props) {
        super(props);
        
    }

    onKeydown(e) {
        const keyD = e.key !== undefined ? e.key : e.keyCode;
        // e.key && e.keycode have mixed support - keycode is deprecated but support is greater than e.key
        // I tested within IE11, Firefox, Chrome, Edge (latest) & all had good support for e.key
      
          if ( (keyD === 'Enter' || keyD === 13) || (['Spacebar', ' '].indexOf(keyD) >= 0 || keyD === 32)) {
          // In IE11 and lower, e.key will equal "Spacebar" instead of ' '
      
          // Default behavior is prevented to prevent the page to scroll when "space" is pressed
          e.preventDefault();
          this.props.onClick(e);
        }
      }
    
    render(){
        const {show} = this.props;
        
        if(show == undefined || !show) {
            return(
                <></>
            );
        }
        const {text, onClick} = this.props;
        return(
            <div className="circle-button">
                <svg height="200" width="200" onClick={e => onClick(e)} onKeyDown={e=> this.onKeydown(e)} role='button' tabIndex={0}  alt={text}>
                    <circle cx="100" cy="100" r="80" stroke="black" stroke-width="0" fill="#f59b14" />
                    <text x="50%" y="50%" text-anchor="middle" stroke="#fff" fill="#fff" stroke-width="1px" dy=".4em">{text}</text>
                    {text}  
                </svg> 
            </div>
        );

    }0
}

export default ButtonOrangeCircle;