import React, { Component } from 'react';

import ValidationMessage  from '../ValidationMessage';

import './style.scss';


class InputField extends Component {

    constructor(props) {
        super(props);
        
    }
    
    render(){
        const {show} = this.props;
        
        if(!show) {
            return(
                <></>
            );
        }

        let { value, mandatory, showValidation, showValidationOk, showValidationError, validationErrorMessage} = this.props;
        
        let { labelText } = this.props;
        labelText = labelText!=null ? labelText : '';
        mandatory = mandatory === undefined  ? false : mandatory;
        showValidation= showValidation === undefined ? false : showValidation;
        showValidationOk= showValidationOk === undefined ? false : showValidationOk;
        showValidationError= showValidationError === undefined ? false : showValidationError;
        validationErrorMessage = validationErrorMessage === undefined ? '' : validationErrorMessage;
         
        return(
            <div  className='component-wrapper input-field'>  
                <div className="input-wrapper">
                    <label>{ labelText }</label>
                    <input
                        className={`infl-wrapper ${showValidationError ? 'infl-err' : ''}  ${mandatory ? 'infl-mandatory' : ''}`}
                        value={value}
                        {...this.props}
                       
                        placeholder={mandatory ? `*${this.props.placeholder}` : this.props.placeholder}
                    />
                </div>
               <ValidationMessage 
                    isShow={showValidation} 
                    isShowOk={showValidationOk} 
                    isShowError={showValidationError} 
                    className='field-valid' 
                    errorMessage={validationErrorMessage}/>
                   
            </div>  
        );
    }
}

export default InputField;