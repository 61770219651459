import React, { Component } from 'react';
import smallBallsLoader from '../../../assets/svg/mini_balls_loader.svg';
import './style.scss';
class Loader extends Component {


    render(){
        const {show} = this.props;
        
        if(show == undefined || !show) {
            return(
                <noscript></noscript>
            );
        }

        return(

            <div className={'loader-wrapper'} > 
                <img src={smallBallsLoader} />
            </div>

        );
    }

}
export default Loader;