import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';
import MenuItem from './MenuItem';
import OpenMenuButton from './OpenMenuButton';
import './style.scss';



class Menu extends Component {
    constructor(props) {
        super(props);
        
        this.INIT_STATE = {
            isActive: false,
            
           
        };
        
        this.state = this.INIT_STATE;
    }

    setActiveMenu(value){
        this.setState({isActive:value});
    }

    render(){
        const {text, history, isAuthenticated,  ...props } = this.props;
        const  homeText = isAuthenticated ? 'התנתק' : 'כניסה למערכת'
        
        if(isAuthenticated) {
            
            return(

                <div className="menu-wrapper single">
                     <MenuItem 
                        show={true}
                        text={homeText}
                        onClick={ e => history.push('/')} />
                </div>
            );

        }

        return(

            <></>
        );


        return(
            <div className="menu-wrapper">
                <OpenMenuButton
                    isActive={this.state.isActive}
                    onClickMe={e => this.setActiveMenu(e)}
                />
                <div className="menu-items-wrapper">
                    <MenuItem 
                        show={this.state.isActive}
                        text={homeText}
                        onClick={ e => history.push('/')} />
                    <MenuItem 
                        show={this.state.isActive}
                        text="חפש חבילה "
                        onClick={ e => history.push('/SearchPackageScreen')} />
                    
                    
                </div>
                
            </div>
        );
    }
}

export default withRouter(Menu);