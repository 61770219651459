import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as   Router, Route, Switch  } from 'react-router-dom';
import Store from './store';

import AppLayout from './components/AppLayout'
import LoginScreen  from './components/screens/LoginScreen';
import SearchPackageScreen from './components/screens/SearchPackageScreen';


import './App.scss';


class App extends Component {

  constructor(props) {
      super(props);

      //this.autoLogouterTrigger = null;
  }

  componentWillMount() {
      //initAxiosLogger();
  }

  componentDidMount() {
      //document.addEventListener('click', () => {
          // if (isProdEnv()) {
          //     new Audio(beep).play();         //! UNCOMMENT TO GET SOUND!
          // }

          //this.autoLogouterTrigger(new Date().getMilliseconds());
      //});

      //console.log(Environment, AppVersion);
  }

  triggerCallbackSetter(triggerFunc, cntx) {
      //console.log('App - triggerCallbackSetter :'+cntx);
      //this.autoLogouterTrigger = (clickTime) => triggerFunc(cntx, clickTime);
  }

  render() {
      return (
          <Provider store={Store}>
              <Router>
                <AppLayout>
                  <Switch>
                      <Route path='/SearchPackageScreen' component={SearchPackageScreen} />
                      <Route path='/' component={LoginScreen} />
                  </Switch>
                </AppLayout>
              </Router>
          </Provider>
      );
  }
}
//
export default App;
