
import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import OrderReducer from './OrderReducer';
import OrderStatusesReducer from './OrderStatusesReducer';

export default combineReducers({
    users: UserReducer,
    orders: OrderReducer,
    orderStatuses: OrderStatusesReducer,
});
