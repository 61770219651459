import React, { Component } from 'react';
import { connect } from 'react-redux';

import Menu from '../common/Menu';
import logo from '../../assets/images/logo_laundry.png';

import './style.scss';

//const AppLayout = ({ children }) => {
 
class AppLayout extends Component {
        constructor(props) {
            super(props);
            
            this.INIT_STATE = {
                isActive: false,
            };
            
            this.state = this.INIT_STATE;
        }
    
        setActiveMenu(value){
            this.setState({isActive:value});
        }
    

    render(){

        return (
            <div className='app-wrapper'>
                <div className="header-wrapper">
                    <div className="logo-wrapper">
                        <img src={logo} />
                    </div>
                    <Menu  isAuthenticated={this.props.authenticated} />
                </div>
                
                
                <div className='content-wrapper'>
                    <div className='content'>
                        {this.props.children}
                    </div>
                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ users }) => {
    const { authenticated,  } = users;
    return {
        authenticated
    };
};
export default connect(mapStateToProps)(AppLayout);

//export default AppLayout;

