export const isMobileNumInvalid = (mobile) => {
    if (!mobile || mobile.length === 0) {
        return 'אנא הזן מספר טלפון';
    }

    if (mobile.length !== 10) {
        return 'על מספר הטלפון של המשתמש להיות בעל 10 ספרות בלבד'
    }

    if (mobile[0] + mobile[1] !== '05') {
        return 'על מספר הטלפון להתחיל בספרות \'05\'';
    }

    return false;
};

export const isEmptyField = (value) => {
   
    return value === undefined || value === null || value === '' || value.length === 0 ;
}
export const isNumber0  = (value, type) => { 

    return (value === 0 || value === '0') && (type === 'number' || type === 'percent'); 
}

export const isValidField = (value, fieldType, isRequired, compareMinValue=null, compareMaxValue=null) => {
    
    const isEmpty = isEmptyField(value) || isNumber0(value, fieldType);

    //TODO CHECK if( fieldType ==='requiredSelect'){ debugger }
    if(isRequired && isEmpty){ return false; }
    if(isEmpty){ return true; }

    switch (fieldType) {
        case 'mobile':

            if (!value || value.length === 0) {
                return false;// 'נא הזן מספר טלפון';
            }
            if(isNaN(value)) { return false; } 
            if (value.length !== 10) {
                return false;// 'על מספר הטלפון של המשתמש להיות בעל 10 ספרות בלבד'
            }

            if (value[0] + value[1] !== '05') {
                return false;// 'על מספר הטלפון להתחיל בספרות \'05\'';
            }
            return true;

        case 'number':
            if(!isNaN(value)) { return true; } 
            return true;

        case 'percent':
            if(isNaN(value)) { return false; } 
            return ((value-0) <= 100);

        case 'text':
            return (value.length <=400);
            
        case 'name':
            return (value.length <=50 && value.match("^[a-zA-Zא-ת ]*$") != null);

        case 'packageNumber':
            return (value.length <= compareMaxValue &&
                value.length >= compareMinValue  && 
                value.match("^[a-zA-Z0-9]*$") != null);
            //return (value.length <=20 && value.match("^[a-zA-Z0-9]*$") != null);
        case 'date':
            let valueDate = new Date(value);
            if(isNaN(valueDate)) { 
                return false;
            }
           
            valueDate.setHours(0,0,0,0);
            if(compareMinValue != null){
                if(valueDate < compareMinValue) return false;
            }
            if(compareMaxValue != null){
                if(valueDate > compareMaxValue) return false;
            }
            return true;
        //case 'dateInterval':
        //  return (Date(value[1])-Date(value[0])) > 0 ;
        default:
            return true;
        }

        
 
};


export const FIELD_TYPE_ENUM =
{
    'name': { 
        regex: "^[a-zA-Z ]*$",
        errMessage:'שדה שם לא תקין.',
        },
    'number': { 
        regex: "^[0-9.]{20}$",
        errMessage:'מספר לא תקין.',
        },
    'percent': { 
            regex: "^[0-9.]{20}$",
            errMessage:'מספר אחוזים לא תקין.',
            },
    'text': { 
        regex: "^[0-9.]{20}$",
        errMessage:'שדה טקסט לא תקין.',
        },
    'mobile': { 
        regex: "^[0-9]{3}[0-9]{7}$",
        errMessage:'מספר טלפון לא תקין.',
        },
    'date': { 
        regex:'',// "^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$",
        errMessage:'תאריך לא תקין.',
        },
    'packageNumber':{
        regex: "^[a-zA-Z0-9]{1-20}$",
        errMessage:'שם קופון לא תקין.',
    },
    'requiredSelect':{
        regex:"",
        errMessage:' אנא בחר מהרשימה.'
        },
    'required':{
        regex:"",
        errMessage:'שדה חובה.',
    }
        
        
}

