import axios from 'axios';
import api from '../api/BaseApi';
//import errorManager from '../utils/ErrorManager';

import {
    ON_USER_SIGN_IN_SUCCESS, 
    ON_USER_SIGN_IN_FAIL,
    ON_USER_SIGN_OUT,
    ON_USER_SIGN_IN_REQUEST,
} from './types';



export const onUserSignInRequest = () => {
    return (dispatch) => {

        dispatch({
            type: ON_USER_SIGN_IN_REQUEST
        });
    }
}

export const onUserSignInClick = (mobile, pwd) => {
    return (dispatch) => {

        axios.post(api.signIn, api.signInBody(mobile, pwd))
            .then(res => {
                const { id: userId, role, firstName, lastName, token, isPasswordTemporary } = res.data;

                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`; //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjZmOTE1NDI0LTk0NmMtNGZmMi0xMGNhLTA4ZDZmNTVhNGJlZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiMDUyNDI0MDkzNCIsImVtYWlsIjoieXVsaWFnQGVmY29tLmNvLmlsIiwicm9sZSI6Ik1hbmFnZXIiLCJuYmYiOjE1NjEwMjAwOTAsImV4cCI6MTU2MTI3OTI5MCwiaWF0IjoxNTYxMDIwMDkwfQ.x2fDsgYvfhIafL4DnL-U6w7aqxWR4EzsIZ4CVSaL-VM';
                dispatch({
                    type: ON_USER_SIGN_IN_SUCCESS,
                    payload: { userId, role, firstName, lastName, token, isPasswordTemporary }
                });
                //errorManager.throwDispatchErrorReset(dispatch,res);
                console.log('LOGIN - ' + token);
            })
            .catch(err => {
                if (err.message === 'Network Error') {
                  
                        dispatch({
                            type: ON_USER_SIGN_IN_FAIL,
                            payload: {
                                //errTitle: err.response.data.message,
                                //errText: err.response.status
                                errTitle: 'err_title_text',     //'שגיאה',
                                errText: 'network_error_text'   //'תקלת תקשורת זמנית'
                            }
                        });
                   
                    return;
                }

                
                dispatch({
                    type: ON_USER_SIGN_IN_FAIL,
                    payload: {
                        //errTitle: err.response.data.message,
                        //errText: err.response.status
                        errTitle: 'err_title_text',    //'שגיאה',
                        errText: 'wrong_login_err_body_text',   //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
                        // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
                    }
                });
               
            });
    };
};


export const onUserSignOut = () => {
    return (dispatch) => {

        dispatch({
            type: ON_USER_SIGN_OUT
        });
    }
}

