import React, { Component } from 'react';
import './style.scss';



class OpenMenuButton extends Component {

    constructor(props) {
        super(props);
        this.INIT_STATE = {
            isActive: false,
        };
        
        this.state = this.INIT_STATE;
    }

    toggleMenu(){
        
        this.props.onClickMe(!this.state.isActive);
        this.setState({isActive: !this.state.isActive});
    }

    render(){
        const { isActive } = this.state;
        const { onClickMe, ...props } = this.props;
        const activeClass = isActive ? 'plate plate4 active' : 'plate plate4';
        
        return(
            <div className="open-menu-btn">
                <div className={activeClass} onClick={e => this.toggleMenu(e)}>
                    <svg className="burger" height="100" width="100" viewBox="0 0 100 100">
                        <path className="line line1" d="M 50,35 H 30" />
                        <path className="line line2" d="M 50,35 H 70" />
                        <path className="line line3" d="M 50,50 H 30" />
                        <path className="line line4" d="M 50,50 H 70" />
                        <path className="line line5" d="M 50,65 H 30" />
                        <path className="line line6" d="M 50,65 H 70" />
                    </svg>
                    <svg className="x" height="100" width="100" viewBox="0 0 100 100">
                        <path className="line" d="M 34,32 L 66,68" />
                        <path className="line" d="M 66,32 L 34,68" />
                    </svg>
                </div>
            </div>
            
        );
    }
}

export default OpenMenuButton;




