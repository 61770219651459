import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../../common/Title';
import Button from '../../common/Button';
import Message from '../../common/Message';

import Loader  from '../../common/Loader';
import { isValidField } from '../../../utils/FieldsValidation';
import { getShortDate } from '../../../utils/FormatedDate';

import { 
    onStartLoading,
    onClearOrderList,
    onFetchOrdersByPackageNumber,
    fetchOrderStatuses,
} from '../../../actions';

import './style.scss';
import InputField from '../../common/InputField';


class SearchPackageScreen extends Component {
    constructor(props) {
        super(props);
        this.INIT={
            packageNumber:'',
            showLoading: false,
            showSubmitButton:false,
            showMessage:false,
            isError:false,
            validField:{
                'packageNumber': { type:'packageNumber',  isRequired:true, isChecked:false,compareMinValue: 1, compareMaxValue: 30, isValid:false },
               },
            lastSearch:'',     
        };

        this.state = this.INIT;
    }

    componentDidMount(){

        const { authenticated, history } = this.props;
        if( !authenticated){
            history.push('/');
        }
        this.props.onClearOrderList();
        this.props.fetchOrderStatuses();
    }

    componentWillUnmount(){
        this.state = this.INIT_STATE;
        this.props.onClearOrderList();
    }

    componentWillReceiveProps(newProps) {
       
        const { authenticated, history } = newProps;
        if(!authenticated){
            history.push('/');
        }
     }

    
     
    setValue(inputName, e){
        
        switch(inputName){
            case 'packageNumber':
                this.setState({packageNumber: e.target.value.trim()});
                break;
         
            default:
                break;

        }
    }

    onInputFocus(inputName){
        
        let { validField } = this.state;
        let field = validField[inputName];
        if(!field) return;

        field.isChecked = false;
        field.isValid = false;
        validField[inputName] = field;
        
        this.setState({
            ...this.state,
            validField, 
        });

    }
    validateInputValue(inputName, value){
        
        let { validField } = this.state;
        let field = validField[inputName];
        if(!field) return;

        field.isChecked = true;
        field.isValid = isValidField( value, field.type,  field.isRequired, field.compareMinValue, field.compareMaxValue );
        validField[inputName] = field;
        
        this.setState({
            ...this.state,
            validField, 
        });
    }

    isValidForm(order){
        const {validField} = this.state;
        this.validateInputValue('packageNumber', order.packageNumber);
      
        const isValid = validField.packageNumber.isChecked &&  validField.packageNumber.isValid; 
        
        return isValid;
    }

    onClickSearchOrder(){
        
        const { packageNumber } = this.state;
        if(!this.isValidForm( { packageNumber })) {return;}

        this.props.onStartLoading();
        this.props.onFetchOrdersByPackageNumber(packageNumber);
        this.setState({lastSearch:packageNumber, packageNumber:''});
            
    }




    render() {
        const { isLoading, orderList, ordersStatusesArr } =this.props;
        const { validField, showMessage, isError, lastSearch } =this.state;
        const showSubmitButton = true ;
        const list = orderList ? orderList : [];

       
        if( isLoading ){
            return (

                <div>
                    <Title text="חיפוש חבילה"/>
                    <Loader show={isLoading} />
                </div>
            );
        }
        let lastSearchText = lastSearch.length >0 ? 'תוצאות חיפוש עבור ' + lastSearch : '';
        let searchResultText = '';
        if(lastSearch.length >0 && list.length <= 0) {searchResultText = 'לא נמצאו רשומות.'}
        if(list.length === 1) {searchResultText = 'נמצאה רשומה אחת.'}
        if(list.length > 0){ searchResultText = 'נמצאו '+list.length+' רשומות.'}


        return (

            <div>
                <Title text="חיפוש חבילה"/>
                <InputField 
                    show={true}
                    value={this.state.packageNumber}
                    iconStyle=""
                    placeholder="מספר חבילה"
                    mandatory={true}
                    showValidation={validField.packageNumber.isChecked}
                    showValidationOk={validField.packageNumber.isValid}
                    showValidationError={!validField.packageNumber.isValid}
                    validationErrorMessage="מספר חבילה לא תקין"
                    onFocus={e=> this.onInputFocus('packageNumber')}
                    onBlur={e=> this.validateInputValue('packageNumber', e.target.value)}
                    onChange={e=> this.setValue('packageNumber',e)}
                    maxLength={30}
                />

                <div>
                    <Button 
                        show={showSubmitButton} 
                        text="חפש" 
                        onClick={e => this.onClickSearchOrder()} 
                    />
                    
                </div>
               <div>
                   {lastSearchText}
               </div>
                <div>
                   {searchResultText}
                </div> 
                
                <div>

                    {list.map((order, index) => {

                          const branchName = order.branchDisplayName !== null && order.branchDisplayName.length>0 ?order.branchDisplayName : order.branchName;
                          const branchAddress = order.branchDisplayAddress !== null && order.branchDisplayAddress.length>0 ?order.branchDisplayAddress : order.branchAddress;

                          return (
                              <div  className="order-item">
                                  <div className="order-item-index">{index+1}</div>
                                  <div className="order-item-info">
                                        <div className="order-item-label">מספר חבילה</div>
                                        <div className="order-item-data">{order.packageNumber}</div>
                                  </div>
                                 <div className="order-item-info">
                                        <div className="order-item-label">שם המקבל</div>
                                        <div className="order-item-data">{order.firstName} {order.lastName}</div>
                                  </div>
                                  <div className="order-item-info position-wrapper">
                                        <div className="order-item-label">עמדה</div>
                                        <div className="order-item-data">{branchName}</div>
                                  </div>
                                  <div className="order-item-info position-wrapper">
                                        <div className="order-item-label">כתובת</div>
                                        <div className="order-item-data">{branchAddress}</div>
                                  </div>
                                  <div className="order-item-info position-wrapper">
                                        <div className="order-item-label">מספר לוקר</div>
                                        <div className="order-item-data">{order.lockerNumber}</div>
                                  </div>
                                  <div className="order-item-info">
                                        <div className="order-item-label">קוד לקבלת חבילה</div>
                                        <div className="order-item-data">{order.deliveryLoginCode}</div>
                                  </div>
                                  <div className="order-item-info">
                                        <div className="order-item-label">תאריך הזמנה</div>
                                        <div className="order-item-data">{getShortDate(order.orderDate)}</div>
                                  </div>
                                  <div className="order-item-info">
                                        <div className="order-item-label">סטטוס הזמנה</div>
                                        <div className="order-item-data">{ordersStatusesArr[order.orderStatus]}</div>
                                  </div>
                              </div>
                          );
                        })}
                </div>
                <div>
                   * לא יוצגו הזמנות שנוצרו יותר מלפני 3 חודשים
                </div>
                <Message 
                    show={showMessage}
                    isError={isError}
                    successText="ההזמנה הוספה בהצלחה"
                    errorText="הוספת הזמנה נכשלה"
                    onClick={e => this.onMessageCkickOk(e)}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ users, orders, orderStatuses }) => {
    const { authenticated } = users;
    const {isLoading, orderList } = orders;
    const { ordersStatusesArr } = orderStatuses;

    return {
        authenticated,
        isLoading, 
        orderList,
        ordersStatusesArr,
    };
};

export default connect(mapStateToProps, {onStartLoading, onClearOrderList, onFetchOrdersByPackageNumber, fetchOrderStatuses})(SearchPackageScreen);
