
//RESET ALL
export const RESET_ALL_REDUCERS = 'RESET_ALL_REDUCERS';

//
export const ON_START_LOADING = 'ON_START_LOADING';
export const ON_CLEAR_ORDER_LIST = 'ON_CLEAR_ORDER_LIST';

//USER
export const ON_USER_SIGN_IN_SUCCESS = 'ON_USER_SIGN_IN_SUCCESS';
export const ON_USER_SIGN_IN_FAIL = 'ON_USER_SIGN_IN_FAIL';
export const ON_USER_SIGN_OUT = 'ON_USER_SIGN_OUT';
export const ON_USER_SIGN_IN_REQUEST = 'ON_USER_SIGN_IN_REQUEST';

//ORDERS LIST
export const ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS = 'ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS';
export const ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL = 'ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL';

//CREATE NEW ORDER
export const ON_CREATE_ORDER_SUCCESS = 'ON_CREATE_ORDER_SUCCESS';
export const ON_CREATE_ORDER_FAIL = 'ON_CREATE_ORDER_FAIL';

//UPDATE ORDER
export const SET_ORDER_TO_UPDATE = 'SET_ORDER_TO_UPDATE';
export const ON_UPDATE_ORDER_SUCCESS = 'ON_UPDATE_ORDER_SUCCESS';
export const ON_UPDATE_ORDER_FAIL = 'ON_UPDATE_ORDER_FAIL';


//ORDER STATUSES
export const ON_GET_ORDER_STATUSES_SUCSESS = 'ON_GET_ORDER_STATUSES_SUCSESS';
export const ON_GET_ORDER_STATUSES_FAIL = 'ON_GET_ORDER_STATUSES_FAIL';

//ON CLOSE MESSAGE
export const ON_CLOSE_MESSAGE = 'ON_CLOSE_MESSAGE';


  
