import { isProdEnv } from '../utils/Env';


const api = {};
let BASE_URL ='';

if(isProdEnv()){
    BASE_URL = 'https://bo-api.cleanbox.co.il';
}else{
    BASE_URL = 'https://bo-api-test.cleanbox.co.il';
    //BASE_URL = 'https://localhost:5001'; //local
}


//LOGIN
api.signIn = `${BASE_URL}/api/ExternalCustomerSupport/authenticate`;
api.signInBody = (mobile, pwd) => {
    return {
        mobilePhone: mobile,
        password: pwd,
    };
};


//ORDERS
api.fetchOrdersByPackageNumber = (packageNumber) => {
    return  `${BASE_URL}/api/ExternalCustomerSupport/FindPackage/${packageNumber}`;
} 

//ORDER STATUSES
api.getOrderStatuses = `${BASE_URL}/api/ExternalCustomerSupport/get_status_types`;

export default api;