import {
    ON_START_LOADING,
    ON_CLEAR_ORDER_LIST,
    ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS,
    ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL,
    ON_CREATE_ORDER_SUCCESS,
    ON_CREATE_ORDER_FAIL,
    SET_ORDER_TO_UPDATE,
    ON_UPDATE_ORDER_SUCCESS,
    ON_UPDATE_ORDER_FAIL,
    ON_CLOSE_MESSAGE,
    RESET_ALL_REDUCERS,
    
    
} from '../actions/types';

const INIT_STATE = {
    orderList:[],
    order:null,
    errTitle: null,
    errText: null,
    isLoading: false,
    isError:false,
    showMessage: false,
    response:null,
};


const OrderReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_START_LOADING:
            return {
                ...INIT_STATE,
                isLoading: true
            };
        case ON_CLEAR_ORDER_LIST:
            return {
                ...INIT_STATE,
                orderList:[],
            };
        case ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS:
            
            return {
                ...state,
                orderList: action.payload.data,
                isLoading: false,
                showMessage: false,
                isError: false,
            };
       
        case ON_CREATE_ORDER_SUCCESS:
            return {
                ...state,
                response: action.payload.data[0],
                isError: !action.payload.data[0].status,
                isLoading: false,
                showMessage: true,
            };
        case SET_ORDER_TO_UPDATE:
            
             return {
                ...state,
                order: action.payload,
                showMessage: false,
                isError: false,
            };
         case ON_UPDATE_ORDER_SUCCESS:
            
            return {
                ...state,
                response: action.payload.data[0],
                isError: !action.payload.data[0].status,
                showMessage: true,
                isLoading: false,
            };
        case ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL:
        case ON_CREATE_ORDER_FAIL:
        case ON_UPDATE_ORDER_FAIL:
            return {
                ...state,
                isError: true,
                errTitle: action.payload.errTitle,
                errText: action.payload.errText,
                showMessage: true,
                isLoading: false
            };
        case ON_CLOSE_MESSAGE:
            return {
                ...state,
                showMessage: false,
            }
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
}

export default OrderReducer;
