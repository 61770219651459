import axios from 'axios';
import api from '../api/BaseApi';
import {
    ON_START_LOADING,
    ON_CLEAR_ORDER_LIST,
    ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS,
    ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL,
    ON_CREATE_ORDER_SUCCESS,
    ON_CREATE_ORDER_FAIL,
    SET_ORDER_TO_UPDATE,
    ON_UPDATE_ORDER_SUCCESS,
    ON_UPDATE_ORDER_FAIL,
    ON_GET_ORDER_STATUSES_SUCSESS,
    ON_CLOSE_MESSAGE,
    ON_GET_ORDER_STATUSES_FAIL
} from '../actions/types';




export const onStartLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ON_START_LOADING
        });
    }
}

export const onClearOrderList = () => {
    return (dispatch) => {
        dispatch({
            type: ON_CLEAR_ORDER_LIST
        });
    }
}

export const onFetchOrdersByPackageNumber = (packageNumber) => {
    return (dispatch) => {

        const options = {
            headers: {
                'Authorization': axios.defaults.headers.common["Authorization"],
            }
        };
        axios.get(api.fetchOrdersByPackageNumber(packageNumber),  options)
            .then(res => {
                console.log(res.data);
                dispatch({
                    type: ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_SUCCESS,
                    payload: res
                });

            })
            .catch(err => {
                if (err.message === 'Network Error') {
                  
                        dispatch({
                            type: ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL,
                            payload: {
                                errTitle: err.response.data.message,
                                //errText: err.response.status
                                //errTitle: 'err_title_text',     //'שגיאה',
                                errText: 'network_error_text'   //'תקלת תקשורת זמנית'
                            }
                        });
                   
                    return;
                }

                
                dispatch({
                    type: ON_FETCH_ORDERS_BY_PACKAGE_NUMBER_FAIL,
                    payload: { //TODO
                        //errTitle: err.response.data.message,
                        //errText: err.response.status
                        errTitle: 'err_title_text',    //'שגיאה',
                        errText: 'wrong_login_err_body_text',   //'אחד או יותר מהפרטים שהוזנו שגויים. ניתן לאפס סיסמא באמצעות לחיצה על \'שכחת סיסמא?\''
                        // errText: 'הלקוח המבוקש לא קיים, תיתכן שגיאה באחד מהשדות'
                    }
                });
               
            });
    };
};



export const onCreateOrder = (packageNumber, mobilePhone, firstName,lastName) => {
    return (dispatch) => {

        const options = {
            headers: {
                'Authorization': axios.defaults.headers.common["Authorization"],
            }
        };

        const body =  [ {
            packageNumber,
            mobilePhone,
            firstName,
            lastName,
            orderStatus:3
        }];

        axios.post(api.createOrder, body, options)
            .then(res => {
                dispatch({
                    type:ON_CREATE_ORDER_SUCCESS,
                    payload: res
                });

            })
            .catch(err => {
                dispatch({
                    type: ON_CREATE_ORDER_FAIL,
                    payload: {
                        errTitle: err.response.data.message,
                        errText: err.response.status
                    }
                });
               
            });
    };
};


export const setSelectedOrder = (order) => {
    return (dispatch) => {
        dispatch({
            type:SET_ORDER_TO_UPDATE,
            payload: order
        });
    }
}

export const closeMessage = () => {

    return (dispatch) => {
        dispatch({
            type:ON_CLOSE_MESSAGE,
        });
    }
}


export const onUpdateOrder = (order) => {
    return (dispatch) => { 
        const options = {
            headers: {
                'Authorization': axios.defaults.headers.common["Authorization"],
            }
        };
        
        axios.post(api.updateOrder, [{...order}] , options)
            .then(res => {
                dispatch({
                    type:ON_UPDATE_ORDER_SUCCESS,
                    payload: res
                });

            })
            .catch(err => {
               
                dispatch({
                    type: ON_UPDATE_ORDER_FAIL,
                    payload: {
                        errTitle: err.response.data.message,
                        errText: err.response.status
                    }
                });
               
            });
    };
};


export const fetchOrderStatuses = () => {
    return (dispath) => {

        axios.get(api.getOrderStatuses)
            .then(res => {
                dispath({
                    type: ON_GET_ORDER_STATUSES_SUCSESS,
                    payload: res.data
                })
                console.log('SUCCESS ON GET ORDER STATUSES');

            }).catch(err => {
                dispath({
                    type: ON_GET_ORDER_STATUSES_FAIL,
                    payload: err
                })
                console.log('FAIL ON GET ORDER STATUSES', err);
            });
    };
};

