import {
    ON_USER_SIGN_IN_SUCCESS,
    ON_USER_SIGN_IN_FAIL,
    ON_USER_SIGN_OUT,
    RESET_ALL_REDUCERS,
    ON_USER_SIGN_IN_REQUEST,
    ON_CLOSE_MESSAGE,
    
} from '../actions/types';

const INIT_STATE = {
 
    userData: null,
    authenticated: false,
    errTitle: null,
    errText: null,
    isLoading: false,
    token:null,
    showMessage: false,
};


const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_USER_SIGN_IN_REQUEST:
            return {
                ...INIT_STATE,
                isLoading: true
            };
        case ON_USER_SIGN_IN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                authenticated: true,
                isLoading: false,
                showMessage: false,
            };
        case ON_USER_SIGN_IN_FAIL:
            return {
                ...state,
                userData: null,
                authenticated: false,
                errTitle: action.payload.errTitle,
                errText: action.payload.errText,
                isLoading: false,
                showMessage: true,
            };
            case ON_CLOSE_MESSAGE:
                return {
                    ...state,
                    showMessage: false,
                }
        case ON_USER_SIGN_OUT:
            return {
                ...INIT_STATE
            };
        
        case RESET_ALL_REDUCERS:
            return {
                ...INIT_STATE
            };
        default:
            return state;
    }
}

export default UserReducer;
