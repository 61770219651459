import React, { Component } from 'react';
import { connect } from 'react-redux';
import Title from '../../common/Title';
import InputField  from '../../common/InputField';
import ButtonOrangeCircle  from '../../common/ButtonOrangeCircle';
import Loader  from '../../common/Loader';
import  {isValidField } from '../../../utils/FieldsValidation';
import Message from '../../common/Message';
import { 
    onUserSignInRequest,
    onUserSignInClick,
    onUserSignOut,
    closeMessage,
} from '../../../actions';

import './style.css';


class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.INIT_STATE = {
            mobile:'',
            passw:'',
            validField:{
                'mobile': { type:'mobile',  isRequired:true, isChecked:false, isValid:false },
                'passw': { type:'text', isRequired:true, isChecked:false, isValid:false },
            },
        };
        
        this.state = this.INIT_STATE;
        
    }

    componentDidMount(){
        const {authenticated} = this.props;
        if(authenticated){
            this.props.onUserSignOut();
        }
        this.state = this.INIT_STATE;
    }

    componentWillReceiveProps(newProps) {
        const { userData } = newProps;

        if (userData) {
            this.props.history.push('SearchPackageScreen');
        }
    }
    
    componentWillUnmount(){
        this.state = this.INIT_STATE;
    }

    setValue(inputName, e){
        
        switch(inputName){
            case 'mobile':
                this.setState({mobile:e.target.value});
                break;
            case 'passw':
                this.setState({passw:e.target.value});
                break;
            default:
                break;

        }
    }

    onInputFocus(inputName){
        
        let { validField } = this.state;
        let field = validField[inputName];
        if(!field) return;

        field.isChecked = false;
        field.isValid = false;
        validField[inputName] = field;
        
        this.setState({
            ...this.state,
            validField, 
        });

    }
    validateInputValue(inputName, value){
        
        let { validField } = this.state;
        let field = validField[inputName];
        if(!field) return;

        field.isChecked = true;
        field.isValid = isValidField( value, field.type,  field.isRequired, field.compareMinValue, field.compareMaxValue );
        validField[inputName] = field;
        
        this.setState({
            ...this.state,
            validField, 
        });
    }



    onClickLoginButton(){
        
        const { validField, mobile, passw } = this.state;
        this.validateInputValue('mobile', mobile);
        this.validateInputValue('passw', passw);
        const isValid = validField.passw.isChecked &&  validField.passw.isValid && validField.mobile.isChecked &&  validField.mobile.isValid;
        if(!isValid) {return;}
        //LOGIN
        this.props.onUserSignInRequest();
        this.props.onUserSignInClick(mobile, passw);
        
    }
    onMessageClickOk(e){
        this.props.closeMessage();
    }
    render() {

        const { validField, mobile, passw } = this.state;
        const { isLoading, showMessage }= this.props;
        const showLoginButton = mobile.length === 10 &&  passw.length >= 5 && passw.length <= 20;

        if(isLoading){
            return (

                <div>
                    <Title text="כניסה למערכת"/>
                    <Loader show={isLoading} />
                </div>
            );
        }


        return (

            <div>
                <Title text="כניסה למערכת"/>
                <div > 
                        <InputField
                            show={true}
                            value={this.state.mobile}
                            placeholder="מספר טלפון"
                            mandatory={true}
                            showValidation={validField.mobile.isChecked}
                            showValidationOk={validField.mobile.isValid}
                            showValidationError={!validField.mobile.isValid}
                            validationErrorMessage="מספר טלפון לא תקין"
                            onFocus={e=> this.onInputFocus('mobile')}
                            onBlur={e=> this.validateInputValue('mobile', e.target.value)}
                            onChange={e=> this.setValue('mobile',e)}
                            maxLength={10}
                        />
                        <InputField
                            show={true}
                            value={this.state.passw}
                            iconStyle=""
                            placeholder="סיסמא"
                            mandatory={true}
                            showValidation={validField.passw.isChecked}
                            showValidationOk={validField.passw.isValid}
                            showValidationError={!validField.passw.isValid}
                            validationErrorMessage="סיסמא לא תקינה"
                            onFocus={e=> this.onInputFocus('passw')}
                            onBlur={e=> this.validateInputValue('passw', e.target.value)}
                            onChange={e=> this.setValue('passw',e)}
                            type="password"
                            maxLength={20}
                        />
                        <div className="buttons-wrapper">
                            <ButtonOrangeCircle 
                                show={showLoginButton} 
                                text="כניסה" 
                                onClick={e => this.onClickLoginButton()} 
                                
                            />
                        </div>
                        <Message 
                            show={showMessage}
                            isError={true}
                            successText=""
                            errorText="אנא בדוק את הפרטים ונסה שוב"
                            onClick={e => this.onMessageClickOk(e)}
                        />
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = ({ users }) => {
    const { authenticated, userData, isLoading, showMessage, } = users;
    
    return {
        authenticated,
        userData,
        isLoading,
        showMessage,
    };
};

export default connect(mapStateToProps, {onUserSignInRequest, onUserSignInClick, onUserSignOut, closeMessage})(LoginScreen);
